@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Syne:wght@400;500;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;




@font-face {
  font-family: "wonder garden script";
  src: url("./fonts/WonderGardenScript-Regular.ttf");
}

@font-face {
  font-family: "BankGothic";
  src: url("./fonts/BankGothic\ Regular.ttf");
}

* {
  font-family: 'Poppins', sans-serif;
}

html,
body {
  scroll-behavior: smooth;
}

@layer base {

  

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000000ab;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


}

@layer components {
  .label {
    @apply text-primary font-medium md:text-white md:text-right md:w-1/5;
  }

  .input {
    @apply w-full border-2 border-primaryHover focus:border-primary font-normal  text-sm outline-none text-textBlackDark py-1 px-3 leading-8 transition-colors duration-700 ease-in-out;
  }

  .input::placeholder{
    @apply font-primary;
  }

  .li {
    @apply list-none text-2xl py-2;
  }

  .primaryFont{
    @apply font-family
  }

  .primaryBlack{
    @apply font-primary text-textBlackDark;
  }

  .primaryLight{
    @apply font-primary text-textBlackLight;
  }

  .button-tomato{
    @apply py-5 px-10 bg-tomato text-white font-primary font-semibold hover:bg-tomatoHover hover:cursor-pointer;
  }

  .button-gold{
    @apply py-5 px-10 bg-gold text-white font-primary font-semibold hover:bg-goldHover hover:cursor-pointer;
  }


}

input::placeholder {
  font-weight: 500 !important;
}

textarea::placeholder {
  font-weight: 500 !important;
}


@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}


.slider{
  display: grid;
  gap: 2rem;
  grid-auto-flow:column;
  grid-auto-columns: 23%;

  padding-bottom: 2rem;

  overflow-x: auto;
  overscroll-behavior-inline: contain ;
}

.snaps-inline{
  scroll-snap-type: inline mandatory;
}

.snaps-inline > * {
  scroll-snap-align: start;
}




/* Makes the animation pause on hover */
.moving-text:hover {
  animation-play-state: paused;
}

/* The animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 15s linear infinite;
  }
}


.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  display: flex;
    /* Change the display to flex */
    align-items: flex-end;
    /* Align items at the vertical bottom */
  animation: marquee 10s linear infinite;
}

.marquee-content img,
.marquee-content .text-content {
  display: inline-block;
}

.text-content {
  margin-right: 20px;
  /* Add some spacing between text and images */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  /* Ensure the container takes the full width */
}

.marquee-content {
  display: inline-flex;
  align-items: flex-end;
  animation: marquee 18s linear infinite;
}

.text-content {
  display: inline-block;
  /* Display as inline-block */
  white-space: nowrap;
  /* Prevent text from wrapping */
}

@keyframes marquee {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.select-none {
  user-select: none;
}

